// console.log('Got to link report modal javascript');

import createAlert from './createAlert';

// Your code to run since DOM is loaded and ready
$(function () {

  $("body").on('click', '.link-report-button', function() {
    const link = JSON.parse(event.target.dataset.link);
    const linkType = event.target.dataset.linkType;

    $("#link-report-athlete-id").val(link.athlete_id)
    $("#link-report-link-type").val(linkType)
    $("#link-report-link-id").val(link.id)
  });

  $('#link-report-form').on('submit', function () {
    $("#close-link-report-modal").trigger('click');

    createAlert('info', 'Thank you for your report. We will review the link and take action if necessary.');
  })

})
